import Component from '_core_ext/components/Component';

export default class TimePickerExt extends Component {
    init() {
        super.init();

        let startTime = this.getTime(this.$el.data('start'));
        let stopTime = this.getTime(this.$el.data('stop'));

        this.startHours = startTime.hours;
        this.startMinutes = startTime.minutes;
        this.stopHours = stopTime.hours || 24;
        this.stopMinutes = this.stopHours === 24 ? 0 : stopTime.minutes;

        this.$elHours = this.$el.find('.js-fd-timepicker-hours');
        this.labelHours = this.$elHours.data('label');
        this.$valueHours = this.$elHours.find('.js-timepicker-value');
        this.valueHours = 0;

        this.$elMinutes = this.$el.find('.js-fd-timepicker-minutes');
        this.labelMinutes = this.$elMinutes.data('label');
        this.$valueMinutes = this.$elMinutes.find('.js-timepicker-value');
        this.valueMinutes = 0;

        this.fillData();
        this.event('click', '.js-fd-timepicker-hours .js-timepicker-button-up', this.upHours);
        this.event('click', '.js-fd-timepicker-hours .js-timepicker-button-down', this.downHours);
        this.event('click', '.js-fd-timepicker-minutes .js-timepicker-button-up', this.upMinutes);
        this.event('click', '.js-fd-timepicker-minutes .js-timepicker-button-down', this.downMinutes);

        let that = this;
        let observer = new MutationObserver(function (mutations) {
            for (const mutation of mutations) {
                if (mutation.attributeName === 'data-start') {
                    let newValue = mutation.target.getAttribute('data-start');
                    let startTime = that.getTime(newValue);

                    that.startHours = startTime.hours;
                    that.startMinutes = startTime.minutes;
                    that.valueHours = that.getHours(that.valueHours);
                    that.valueMinutes = that.getMinutes(that.valueHours, that.valueMinutes);
                    that.render();
                } else if (mutation.attributeName === 'data-stop') {
                    let newValue = mutation.target.getAttribute('data-stop');
                    let stopTime = that.getTime(newValue);

                    that.stopHours = stopTime.hours || 24;
                    that.stopMinutes = that.stopHours === 24 ? 0 : stopTime.minutes;
                    that.valueHours = that.getHours(that.valueHours);
                    that.valueMinutes = that.getMinutes(that.valueHours, that.valueMinutes);
                    that.render();
                }
            }
        });

        this.$el.each(function () {
            observer.observe(this, {
                attributes: true,
                attributeOldValue: true,
                attributeFilter: ['data-start', 'data-stop']
            });
        });
    }

    getTime(timeStr) {
        let result = {
            hours: 0,
            minutes: 0
        };

        if (timeStr) {
            let timeArr = timeStr.split(':');

            if (timeArr.length > 0) {
                result.hours = +timeArr[0] || 0;
                result.minutes = Math.trunc((+timeArr[1] || 0) / 15) * 15;
            }
        }

        return result;
    }

    fillData() {
        let now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();

        this.$elHours.find('.js-timepicker-label').text(this.labelHours);
        this.valueHours = this.getHours(hours);

        this.$elMinutes.find('.js-timepicker-label').text(this.labelMinutes);
        this.valueMinutes = this.getMinutes(this.valueHours, minutes);

        this.render();
    }

    getHours(hours) {
        if (hours < this.startHours) {
            return this.startHours;
        }

        if (hours > this.stopHours) {
            return this.stopHours;
        }

        return hours;
    }

    getMinutes(hours, minutes) {
        let result = 45;

        if (hours === this.startHours) {
            return this.startMinutes;
        }

        if (hours > this.stopHours) {
            return this.stopMinutes;
        }

        if (minutes >= 0 && minutes < 15) {
            result = 0;
        } else if (minutes >= 15 && minutes < 30) {
            result = 15;
        } else if (minutes >= 30 && minutes < 45) {
            result = 30;
        }
        return result;
    }

    upHours() {
        this.valueHours = +this.$elHours.attr('data-value');
        this.valueMinutes = +this.$elMinutes.attr('data-value');

        if (this.valueHours === 23) {
            this.valueHours = this.startHours;
            this.valueMinutes = this.startMinutes;
        } else if (this.valueHours === this.stopHours) {
            if (this.valueMinutes === this.stopMinutes) {
                this.valueHours = this.startHours;
                this.valueMinutes = this.startMinutes;
            } else {
                this.valueMinutes = this.stopMinutes;
            }
        } else if ((this.valueHours + 1) === this.stopHours && this.valueMinutes > this.stopMinutes) {
            this.valueHours++;
            this.valueMinutes = this.stopMinutes;
        } else {
            this.valueHours++;
        }

        this.render();
    }

    downHours() {
        this.valueHours = +this.$elHours.attr('data-value');
        this.valueMinutes = +this.$elMinutes.attr('data-value');

        if (this.valueHours === 0) {
            this.valueHours = this.stopHours < 23 ? this.stopHours : 23;
        } else if (this.valueHours === this.startHours) {
            if (this.valueMinutes <= this.startMinutes) {
                this.valueHours = this.stopHours < 23 ? this.stopHours : 23;
                this.valueMinutes = this.stopMinutes;
            } else {
                this.valueMinutes = this.startMinutes;
            }
        } else if ((this.valueHours - 1) === this.startHours && this.valueMinutes < this.startMinutes) {
            this.valueHours--;
            this.valueMinutes = this.startMinutes;
        } else {
            this.valueHours--;
        }

        this.render();
    }

    upMinutes() {
        this.valueHours = +this.$elHours.attr('data-value');
        this.valueMinutes = +this.$elMinutes.attr('data-value');

        if (this.valueMinutes === 45) {
            if (this.valueHours === this.startHours) {
                this.valueMinutes = this.startMinutes;
            } else {
                this.valueMinutes = 0;
            }
        } else if (this.valueHours === this.stopHours && this.valueMinutes === this.stopMinutes) {
            this.valueMinutes = 0;
        } else {
            this.valueMinutes += 15;
        }

        this.render();
    }

    downMinutes() {
        this.valueHours = +this.$elHours.attr('data-value');
        this.valueMinutes = +this.$elMinutes.attr('data-value');

        this.valueHours = +this.$elHours.attr('data-value');
        this.valueMinutes = +this.$elMinutes.attr('data-value');

        if (this.valueMinutes === 0) {
            if (this.valueHours === this.stopHours) {
                this.valueMinutes = this.stopMinutes;
            } else {
                this.valueMinutes = 45;
            }
        } else if (this.valueHours === this.startHours && this.valueMinutes === this.startMinutes) {
            this.valueMinutes = 45;
        } else {
            this.valueMinutes -= 15;
        }

        this.render();
    }

    render () {
        this.$valueHours.text(`0${this.valueHours}`.slice(-2));
        this.$elHours.attr('data-value', `0${this.valueHours}`.slice(-2));

        this.$valueMinutes.text(`0${this.valueMinutes}`.slice(-2));
        this.$elMinutes.attr('data-value', `0${this.valueMinutes}`.slice(-2));
    }
}
