'use strict';

import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('fdsteps');

const CLASS_PATTERN = '.js-fd-step';
const DEFAULT_COUNTRY = 'IE';
const STORAGE_TTL = 8.64e7;
const TIME = {
    Dublin: 12,
    Cork: 48,
    months: 3,
    strictDublin: 12
};
const moment = require('moment');

export default class FlightDetailsSteps extends Component {
    init() {
        super.init();
        this.error = '';
        this.step = window.localStorage.getItem('fdStep') ? 1 * window.localStorage.getItem('fdStep') : 1;

        if (this.step > 1) {
            window.localStorage.removeItem('fdStep');
        }

        // jscs: disable
        this.sessionCountry = this.$el.attr('data-session') !== 'null' ? this.$el.attr('data-session') : null;
        // jscs: enable
        this.locale = this.$el.data('locale');
        this.checkURL = $('.js-fd-step-10').attr('data-url');
        this.workTimeURL = $('.js-fd-step-7').attr('data-url');
        this.state = this.getState();
        this.isStaff = this.$el.attr('data-staff') === 'true';
        this.containsAlcohol = this.$el.attr('data-contains-alcohol') === 'true';
        if (this.state.date) {
            // bootstrap timpicker fix - default date can't be less them today
            let date = new Date(this.state.date),
                today = new Date().getTime();

            date.setHours(1);
            if (date < today) {
                date = today;
            }
            $('.js-datetimepicker').data('DateTimePicker').options({
                defaultDate: new Date(date),
                format: 'LL'
            });
        }

        $('.js-fd-button-flightnumber-next').addClass('disabled');

        this.configTerminals();
        this.setTimeMargins();
        this.setTerminalLabels();
        this.fillResult();
        this.showCurrentStep();
        this.fireEvents();
        this.getCustomText();
    }

    getState () {
        let state = {
            citytype: 'departure',
            city: 'Dublin',
            terminal: null,
            connections: [],
            connectionsCount: 1,
            date: null,
            time: null,
            isFlying: true,
            country: {
                from: {
                    value: null, label: null
                },
                to: {
                    value: null, label: null
                }
            },
            isTransfer: false,
            flightNumber: '',
            customized: {
                isCustomized: false,
                customizedObj: null
            },
            locale: this.locale,
            timestamp: new Date().getTime()
        },
        storedState = {};

        if (window.localStorage.getItem('fdState')) {
            try {
                storedState = JSON.parse(window.localStorage.getItem('fdState'));
                // data not restore if user changed locale or localStorage saved more than 24 hours
                if (storedState.locale === this.locale && storedState.timestamp && state.timestamp - storedState.timestamp < STORAGE_TTL) {
                    state.date = storedState.date || '';
                    state.city = storedState.city || 'Dublin';
                    state.citytype = storedState.citytype || 'departure';
                    state.time = storedState.time || '';
                    state.isTransfer = storedState.isTransfer || false;
                    state.connections = storedState.connections || [];
                    state.phone = storedState.phone || '';
                    state.connectionsCount = storedState.connectionsCount || 1;
                    state.country = storedState.country;
                    state.isFlying = storedState.isFlying;
                    state.terminal = storedState.terminal || null;
                    state.flightNumber = storedState.flightNumber || '';
                } else {
                    localStorage.removeItem('fdState');
                }
            } catch (e) {
                // console.warn(`Couldn't parse FD storage: ${e}`);
            }
        }

        return state;
    }

    fireEvents () {
        this.event('click', '.js-fd-go-back', this.goBack);

        // Firdt step started
        this.event('click', '.js-fd-button-proceed', () => {
            if (this.isStaff) {
                this.showAndHide(['.js-fd-secondstep-staff', '.js-fd-secondstep-regular']);
            } else {
                this.showAndHide(['.js-fd-secondstep-regular', '.js-fd-secondstep-staff']);
            }
            this.showStepNumber(3);
        });

        // Screen Why
        this.event('click', '.js-fd-button-why', () => {
            this.showStepNumber(2);
        });

        // select a type of arriving (departure) or leaving
        this.event('click', '.js-fd-button-out-ireland', () => {
            this.state.citytype = 'departure';
            this.state.isFlying = true;
            this.state.terminal = null;
            this.showStepNumber(4);
        });
        this.event('click', '.js-fd-button-in-ireland', () => {
            this.state.citytype = 'arrival';
            this.state.isFlying = true;
            this.showStepNumber(4);
        });

        // Start Staff flow
        this.event('click', '.js-fd-button-flying-yes', () => {
            this.state.isFlying = true;
            this.showAndHide(['.js-fd-secondstep-regular', '.js-fd-secondstep-staff']);
        });
        this.event('click', '.js-fd-button-flying-no', () => {
            this.state.isFlying = false;
            this.state.isTransfer = false;
            this.saveCountry();
            this.showStepNumber(4);
        });

        // Airport selecting
        this.event('click', '.js-fd-button-dublin', () => {
            if ($('.js-flightdetails-steps-container') && $('.js-flightdetails-steps-container').data('marginsJson')) {
                var minimalDelay = $('.js-flightdetails-steps-container').data('marginsJson').minimalDublin;
                var newMinDate = moment().add(minimalDelay, 'hours').startOf('day');

                $('.js-datetimepicker').datetimepicker().data('DateTimePicker').minDate(newMinDate);
            }

            this.state.city = 'Dublin';
            this.showStepNumber(5);
        });
        this.event('click', '.js-fd-button-cork', () => {
            if ($('.js-flightdetails-steps-container') && $('.js-flightdetails-steps-container').data('marginsJson')) {
                var minimalDelay = $('.js-flightdetails-steps-container').data('marginsJson').minimalCork;
                var newMinDate = moment().add(minimalDelay, 'hours').startOf('day');

                $('.js-datetimepicker').datetimepicker().data('DateTimePicker').minDate(newMinDate);
            }

            this.state.city = 'Cork';
            this.state.terminal = 'terminal';
            this.showStepNumber(5);
        });

        this.event('click', '.js-fd-button-homedelivery', () => {
            this.state.terminal = 'homedelivery';
            this.prepareStaffTime();
            this.fillForm();
            this.fillResult();
            this.showStepNumber(12);
        });

        // Terminal selecting
        this.event('click', '.js-fd-button-terminal1', () => {
            this.state.terminal = 'Terminal 1';
            this.changeCityType();
            this.showStepNumber(6);
        });
        this.event('click', '.js-fd-button-terminal2', () => {
            this.state.terminal = 'Terminal 2';
            this.changeCityType();
            this.showStepNumber(6);
        });
        this.event('click', '.js-fd-button-grattan', () => {
            this.state.terminal = 'Grattan';
            this.changeCityType();
            this.prepareStaffTime();
            this.fillForm();
            this.fillResult();
            this.showStepNumber(12);
        });
        this.event('click', '.js-fd-button-clive', () => {
            this.state.terminal = 'Clive';
            this.changeCityType();
            this.prepareStaffTime();
            this.fillForm();
            this.fillResult();
            this.showStepNumber(12);
        });
        this.event('click', '.js-fd-button-terminal-arrival, .js-fd-button-terminal-departure', () => {
            this.state.terminal = 'terminal';
            this.changeCityType();
            this.showStepNumber(6);
        });
        // Date - time selection
        this.event('click', '.js-fd-button-calendar-date', () => {
            this.state.date = $('.js-fd-button-calendar-date').text();
            if (this.validateMonth()) {
                $('.js-select-time-main').removeClass('h-hidden');
                this.setWorkTime().then(() => {
                    this.showStepNumber(7);
                    this.storeState();
                });
            } else {
                $('.js-fd-progress-line').width(`100%`);
                this.showAndHide(['.js-fd-step-6-error', '.js-fd-step-6-main']);
            }
        });
        this.event('click', '.js-fd-button-calendar-time', this.onTimeSelected);

        // Direction selection
        this.event('click', '.js-fd-button-direct', () => {
            this.showAndHide(['.js-flightdetails-direct', '.js-flightdetails-connection', '.js-flightdetails-connection-countries']);
            this.state.isTransfer = false;
            emitter.emit('closeselector');
            this.prefillSelectors();
            this.showStepNumber(9);
        });
        this.event('click', '.js-fd-button-connection', () => {
            this.showAndHide(['.js-flightdetails-connection', '.js-flightdetails-direct', '.js-flightdetails-connection-countries']);
            this.state.isTransfer = true;
            if (this.state.connectionsCount > 1) {
                $('.js-fd-connection-quantity').val(`${this.state.connectionsCount}`);
            }
            emitter.emit('closeselector');
            this.prefillSelectors();
            this.showStepNumber(9);
        });

        this.event('click', '.js-fd-button-from-to', () => {
            if ($('.js-fd-country-from').attr('data-value') && $('.js-fd-country-to').attr('data-value')) {
                this.state.country = {
                    from: {
                        label: $('.js-fd-country-from').attr('data-label'),
                        value: $('.js-fd-country-from').attr('data-value')
                    },
                    to: {
                        label: $('.js-fd-country-to').attr('data-label'),
                        value: $('.js-fd-country-to').attr('data-value')
                    }
                };
                emitter.emit('closeselector');
                this.saveCountry();
                if (this.checkStepAvailability(10)) {
                    this.showStepNumber(10);
                } else if (SitePreferences.DISPLAY_NOTIFICATION_STEP) {
                    this.showStepNumber(11);
                } else {
                    this.skipPhoneStep();
                }
            }
        });
        this.event('click', '.js-fd-button-connection-points', () => {
            this.state.country = {
                from: {
                    label: $('.js-fd-country-connection-from').attr('data-label'),
                    value: $('.js-fd-country-connection-from').attr('data-value')
                },
                to: {
                    label: $('.js-fd-country-connection-to').attr('data-label'),
                    value: $('.js-fd-country-connection-to').attr('data-value')
                }
            };
            emitter.emit('closeselector');
            this.saveCountry();
            this.showAndHide(['.js-flightdetails-connection-countries', '.js-flightdetails-connection']);
            this.prepareConnections();
        });
        this.event('change', '.js-fd-connection-quantity', () => {
            this.state.connectionsCount = $('.js-fd-connection-quantity').val();
            emitter.emit('closeselector');
            this.storeState();
        });

        this.event('click', '.js-fd-button-connection-proceed', () => {
            this.state.country = {
                from: {
                    label: $('.js-fd-country-connection-from').attr('data-label'),
                    value: $('.js-fd-country-connection-from').attr('data-value')
                },
                to: {
                    label: $('.js-fd-country-connection-to').attr('data-label'),
                    value: $('.js-fd-country-connection-to').attr('data-value')
                }
            };
            emitter.emit('closeselector');
            this.saveCountry();
            this.prepareConnections(true);
            if (this.checkStepAvailability(10)) {
                this.showStepNumber(10);
            } else if (SitePreferences.DISPLAY_NOTIFICATION_STEP) {
                this.showStepNumber(11);
            } else {
                this.skipPhoneStep();
            }
        });

        this.event('click', '.js-fd-button-savephone', () => {
            this.state.phone = $('.js-fd-input-phone-number').val();
            if (this.state.phone.trim() === '') {
                $('.js-fd-phone-reminder-error').removeClass('h-hidden');
            } else {
                $('.js-fd-phone-reminder-error').addClass('h-hidden');
                this.fillResult();
                this.fillForm();
                this.showStepNumber(12);
            }
        });

        this.event('click', '.js-fd-button-submit-fd', () => {
            this.state.country = {
                from: {
                    label: $('.js-fd-country-from').attr('data-label'),
                    value: $('.js-fd-country-from').attr('data-value')
                },
                to: {
                    label: $('.js-fd-country-to').attr('data-label'),
                    value: $('.js-fd-country-to').attr('data-value')
                }
            };
            emitter.emit('closeselector');
            this.saveCountry();
            this.fillForm();
            this.fillResult();
        });

        this.event('click', '.js-fd-button-submit-cfd', () => {
            this.state.country = {
                from: {
                    label: $('.js-fd-country-connection-from').attr('data-label'),
                    value: $('.js-fd-country-connection-from').attr('data-value')
                },
                to: {
                    label: $('.js-fd-country-connection-to').attr('data-label'),
                    value: $('.js-fd-country-connection-to').attr('data-value')
                }
            };
            emitter.emit('closeselector');
            this.saveCountry();
            this.prepareConnections(true);
            this.fillForm();
            this.fillResult();
        });

        // ignoring "ENTER" key for phone input field
        this.event('keydown', '.js-fd-input-phone-number', (el, e) => {
            if (e.keyCode === 13) {
                e.preventDefault();
            }
        });

        this.event('click', '.js-fd-button-withoutphone', () => {
            this.skipPhoneStep();
        });
        this.event('click', '.js-fd-button-change-citytype', () => {
            if (this.error) {
                this.showAndHide(['.js-select-time-main', `.${this.error}`]);
            }
            this.error = '';
            this.state.citytype = 'arrival';
            this.prefillSelectors();
            this.showStepNumber(6);
        });
        // local links from Flight Connections detail to previous state
        this.event('click', '.js-fd-connection-step-back', () => {
            emitter.emit('closeselector');
            this.showAndHide(['.js-flightdetails-connection', '.js-flightdetails-connection-countries', '.js-flightdetails-direct']);
        });
        this.event('click', '.js-fd-connection-step-back-type', () => {
            emitter.emit('closeselector');
            this.showStepNumber(8);
        });

        // Flight Number checking block
        this.event('click', '.js-fd-button-flightnumber-next', () => {
            this.checkFlightNumber();
        });
        $('.js-fd-input-flight-number').on('focus', () => {
            $('.js-fd-flightnumber-error').addClass('h-hidden').text('');
        });

        // ignoring "ENTER" key for flight Number input field
        this.event('keydown', '.js-fd-input-flight-number', (el, e) => {
            $('.js-fd-button-flightnumber-next').removeClass('disabled');
            if (e.keyCode === 13) {
                e.preventDefault();
            }
        });

        // redirect to Checkout steps from Result step
        this.event('click', '.js-fd-step-12', (el, e) => {
            let $elem = $(e.target),
                step = 1 * $elem.attr('data-step');

            if ($elem.hasClass('link__to-local-step') && step) {
                switch (step) {
                    case 3:
                        if (this.isStaff) {
                            this.showAndHide(['.js-fd-secondstep-staff', '.js-fd-secondstep-regular']);
                        } else {
                            this.showAndHide(['.js-fd-secondstep-regular', '.js-fd-secondstep-staff']);
                        }
                        break;
                    case 5:
                        if (this.isStaff) {
                            this.showAndHide(['.js-fd-step-terminal-staff', '.js-fd-step-terminal-regular']);
                        } else {
                            this.showAndHide(['.js-fd-step-terminal-regular', '.js-fd-step-terminal-staff']);
                        }
                        break;
                    case 9:
                        if (this.state.isTransfer) {
                            this.showAndHide(['.js-flightdetails-connection', '.js-flightdetails-connection-countries', '.js-flightdetails-direct']);
                        } else {
                            this.showAndHide(['.js-flightdetails-direct', '.js-flightdetails-connection', '.js-flightdetails-connection-countries']);
                        }
                        emitter.emit('closeselector');
                        break;
                }
                this.step = step;
                this.showCurrentStep();
            }
        });

        // store connection country when country selector changed
        this.eventMgr('countryselector.change', () => {
            this.prepareConnections(true);
            this.storeState();
        });
    }

    getCustomText () {
        const stepEl = $('.js-fd-step-12')[0];

        if (stepEl) {
            try {
                const dataset = stepEl.dataset;

                this.state.customized.customizedObj = JSON.parse(dataset.staffcontent);
                this.state.customized.isCustomized = this.state.customized.customizedObj ? true : false;
            } catch (err) {} // jshint ignore:line
        }
    }

    skipPhoneStep() {
        $('.js-fd-phone-reminder-error').addClass('h-hidden');
        this.state.phone = '';
        this.fillResult(true);
        this.fillForm();
        this.showStepNumber(12);
    }

    goBack () {
        if (this.step === 1) {
            let basketUrl = $('.b-checkout-flightdetails__back-to-basket').find('a').attr('href');

            window.location.href = basketUrl;
            return;
        }

        switch (this.step) {
            case 3:
                if (this.isStaff) {
                    if ($('.js-fd-secondstep-staff').hasClass('h-hidden')) {
                        this.showAndHide(['.js-fd-secondstep-staff', '.js-fd-secondstep-regular']);
                    } else {
                        this.step = 1;
                    }
                } else {
                    this.step = 1;
                }
                break;
            case 6:
                if ($('.js-fd-step-6-main').hasClass('h-hidden')) {
                    this.showAndHide(['.js-fd-step-6-main', '.js-fd-step-6-error']);
                } else {
                    if (this.isStaff) {
                        this.showAndHide(['.js-fd-step-terminal-staff', '.js-fd-step-terminal-regular']);
                        if (this.state.city === 'Dublin') {
                            if (this.state.isFlying) {
                                this.showAndHide(['.js-fd-step-terminal-regular', '.js-fd-step-terminal-staff']);
                            } else {
                                this.showAndHide(['.js-fd-step-terminal-staff', '.js-fd-step-terminal-regular']);
                                this.showAndHide(['.js-fd-step-terminal-staff-dublin', '.js-fd-step-terminal-staff-cork']);
                            }
                            this.step = 5;
                        } else {
                            if (this.state.isFlying) {
                                this.step = 4;
                            } else {
                                this.showAndHide(['.js-fd-step-terminal-staff-cork', '.js-fd-step-terminal-staff-dublin']);
                                this.step = 5;
                            }
                        }
                    } else {
                        this.showAndHide(['.js-fd-step-terminal-regular', '.js-fd-step-terminal-staff']);
                        if (this.state.city === 'Dublin') {
                            this.step = 5;
                        } else {
                            this.step = 4;
                        }
                    }
                }
                break;

            case 7:
                if (this.error) {
                    this.showAndHide(['.js-select-time-main', `.${this.error}`]);
                    this.error = false;
                } else {
                    this.step--;
                }
                break;

            case 9:
                if ($('.js-flightdetails-connection-countries').hasClass('h-hidden')) {
                    this.step--;
                } else {
                    this.showAndHide(['.js-flightdetails-connection', '.js-flightdetails-connection-countries']);
                }
                emitter.emit('closeselector');
                break;

            case 11:
                if (this.isStaff && !this.state.isFlying) {
                    this.step = 7;
                } else {
                    // for regular user show connections if it presents
                    if (this.state.isTransfer) {
                        this.showAndHide(['.js-flightdetails-connection-countries', '.js-flightdetails-direct', '.js-flightdetails-connection']);
                    } else {
                    // or show direct flight
                        this.showAndHide(['.js-flightdetails-direct', '.js-flightdetails-connection-countries', '.js-flightdetails-connection']);
                    }
                    this.step--;
                }
                emitter.emit('closeselector');
                break;

            case 12:
                if (!this.state.isFlying && this.isStaff) {
                    if (this.state.terminal === 'homedelivery') {
                        this.step = 4;
                    } else {
                        this.showAndHide(['.js-fd-step-terminal-staff', '.js-fd-step-terminal-regular']);
                        if (this.state.city === 'Dublin') {
                            $('.js-fd-step-terminal-staff-dublin').removeClass('h-hidden');
                        } else {
                            $('.js-fd-step-terminal-staff-cork').removeClass('h-hidden');
                        }
                        this.step = 5;
                    }
                } else {
                    this.step--;
                    if (!SitePreferences.DISPLAY_NOTIFICATION_STEP) {
                        this.step--;
                    }
                }
                break;

            default:
                this.step--;
                break;
        }
        this.showStepNumber(this.step, true);
    }

    showCurrentStep() {
        $(CLASS_PATTERN).addClass('h-hidden');
        $(`${CLASS_PATTERN}-${this.step}`).removeClass('h-hidden');
        if (this.step > 1) {
            $('.js-fd-progress-line').width(`${this.step / 12 * 100}%`);
        }
    }

    checkStepAvailability(n) {
        return $(`${CLASS_PATTERN}-${n}`).length;
    }

    showStepNumber (n, isGoBack) {
        while (!this.checkStepAvailability(n)) {
            n += isGoBack ? -1 : 1;
            if (n < 0 || n > 20) {
                break;
            }
        }
        this.step = n;
        switch (this.step) {
            case 4:
                this.prefillSelectors();
                // Dublin / Shannon label
                if (this.isStaff && !this.state.isFlying) {
                    $('.js-fd-button-homedelivery').removeClass('h-hidden');
                } else {
                    $('.js-fd-button-homedelivery').addClass('h-hidden');
                }
                break;

            case 5:
                // showng terminal info
                if (this.state.city === 'Cork') {
                    if (this.isStaff && !this.state.isFlying) {
                        this.showAndHide(['.js-fd-step-terminal-staff', '.js-fd-step-terminal-regular']);
                        this.showAndHide(['.js-fd-step-terminal-staff-cork', '.js-fd-step-terminal-staff-dublin']);
                    } else {
                        this.step = 6;
                    }
                } else {
                    if (this.isStaff && !this.state.isFlying) {
                        this.showAndHide(['.js-fd-step-terminal-staff', '.js-fd-step-terminal-regular']);
                        this.showAndHide(['.js-fd-step-terminal-staff-dublin', '.js-fd-step-terminal-staff-cork']);
                    } else {
                        this.showAndHide(['.js-fd-step-terminal-regular', '.js-fd-step-terminal-staff']);
                    }
                }
                break;

            case 7:
                // restore time if it was stored
                if (this.state.time) {
                    let hours = this.state.time.split(':')[0],
                        minutes = this.state.time.split(':')[1];

                    $('.js-fd-timepicker-hours').attr('data-value', hours).find('.js-timepicker-value').text(hours);
                    $('.js-fd-timepicker-minutes').attr('data-value', minutes).find('.js-timepicker-value').text(minutes);
                }
                break;
            case 11:
                // hide any error message (in case we are returning back to this step again)
                $('.js-fd-phone-reminder-error').addClass('h-hidden');
                // prefill phone number if stored
                if (this.state.phone) {
                    $('.js-fd-input-phone-number').val(this.state.phone);
                    $('.js-fd-button-withoutphone').addClass('disabled');
                }
                break;
        }
        this.showCurrentStep();
    }

    fillResult (hidePhone = false) {
        if (this.isStaff && !this.state.isFlying && this.state.terminal === 'homedelivery') {
            this.showAndHide(['.js-fd-step11-home-delivery-staff', '.js-fd-step11-regular']);

            if (this.containsAlcohol) {
                this.showAndHide(['.js-fd-home-delivery-staff-alcohol', '.js-fd-home-delivery-staff']);
            } else {
                this.showAndHide(['.js-fd-home-delivery-staff', 'js-fd-home-delivery-staff-alcohol']);
            }
        } else {
            this.showAndHide(['.js-fd-step11-regular', '.js-fd-step11-home-delivery-staff']);
            let type;

            // Make correct label for result page
            if (this.isStaff && !this.state.isFlying) {
                type = 'staying in';
            } else {
                type = this.state.citytype === 'departure' ? 'departing' : 'arriving';
            }

            if (this.state.terminal === 'Grattan' || this.state.terminal === 'Clive') {
                let firstLineText = Resources.FLIGHT_SUMMARY_STAFF_FIRST_LINE;
                let secondLineText = Resources.FLIGHT_SUMMARY_STAFF_SECOND_LINE;
                const customizedObj = this.state.customized.customizedObj;

                if (this.state.terminal === 'Grattan') {
                    if (customizedObj && 'grattenHouseFirstLine' in customizedObj && customizedObj.grattenHouseFirstLine) {
                        firstLineText = customizedObj.grattenHouseFirstLine;
                    }
                    if (customizedObj && 'grattenHouseSecondLine' in customizedObj && customizedObj.grattenHouseSecondLine) {
                        secondLineText = customizedObj.grattenHouseSecondLine;
                    }
                }
                if (this.state.terminal === 'Clive') {
                    if (customizedObj && 'cliveHouseFirstLine' in customizedObj && customizedObj.cliveHouseFirstLine) {
                        firstLineText = customizedObj.cliveHouseFirstLine;
                    }
                    if (customizedObj && 'cliveHouseSecondLine' in customizedObj && customizedObj.cliveHouseSecondLine) {
                        secondLineText = customizedObj.cliveHouseSecondLine;
                    }
                }

                // used html to let client paste html in BM field
                this.showAndHide(['.js-fd-step-11-summary-first', '.js-fd-step-11-summary-first__meta']);
                $('.js-fd-step-11-summary-first').html(firstLineText);
                $('.js-fd-step-11-summary-second').html(secondLineText);
            } else {
                this.showAndHide(['.js-fd-step-11-summary-first__meta', '.js-fd-step-11-summary-first']);
            }

            $('.js-fd-review-arriving').text(type);
            $('.js-fd-review-destination').text(this.state.city);
            if (this.state.city === 'Dublin') {
                $('.js-fd-review-terminal-show').removeClass('h-hidden');
            } else {
                $('.js-fd-review-terminal-show').addClass('h-hidden');
            }
            $('.js-fd-review-terminal').text(this.state.terminal);

            if (this.state.terminal !== 'Grattan' && this.state.terminal !== 'Clive') {
                $('.js-fd-review-date').text(this.state.date);
                $('.js-fd-review-time').text(this.state.time ? this.state.time.replace(/:/, 'h') : '');
                this.showAndHide(['.js-fd-result-date-time', '.js-fd-result-no-date-time']);
            } else {
                this.showAndHide(['.js-fd-result-no-date-time', '.js-fd-result-date-time']);
            }

            $('.js-fd-review-from').text(this.state.country.from.label);
            $('.js-fd-review-to').text(this.state.country.to.label);

            if (this.state.isTransfer) {
                $('.js-fd-result-connection-block').removeClass('h-hidden');
                $('.js-fd-review-connection').text(this.state.connections.join(', '));
            } else {
                $('.js-fd-result-connection-block').addClass('h-hidden');
            }

            if (this.state.phone && !hidePhone) {
                $('.js-fd-review-phone').text(`${$('.js-fd-phonenumber-wrapper select').val()} ${this.state.phone}`);
                $('.js-fd-review-phone-wrapper').removeClass('h-hidden');
            } else {
                $('.js-fd-review-phone-wrapper').addClass('h-hidden');
            }

            if (this.isStaff && !this.state.isFlying) {
                $('.js-fd-result-flying-fromto').addClass('h-hidden');
                if (this.state.city === 'Dublin' && this.state.terminal !== 'Terminal 1' && this.state.terminal !== 'Terminal 2') {
                    $('.js-fd-result-staff-information').removeClass('h-hidden');
                    this.showAndHide(['.js-fd-result-start-for-nonflying', '.js-fd-result-start-for-all']);
                } else {
                    $('.js-fd-result-staff-information').addClass('h-hidden');
                    this.showAndHide(['.js-fd-result-start-for-all', '.js-fd-result-start-for-nonflying']);
                }
            } else {
                $('.js-fd-result-flying-fromto').removeClass('h-hidden');
                this.showAndHide(['.js-fd-result-start-for-all', '.js-fd-result-start-for-nonflying']);
            }
        }

        this.storeState();
    }

    onTimeSelected() {
        this.validateDate().then(result => {
            this.error = result;
            if (this.error) {
                $('.js-fd-progress-line').width(`100%`);
                this.showAndHide([`.${this.error}`, '.js-select-time-main']);
            } else {
                if (this.isStaff && !this.state.isFlying) {
                    this.state.time = `${$('.js-fd-timepicker-hours').attr('data-value')}:${$('.js-fd-timepicker-minutes').attr('data-value')}`;
                    this.storeState();
                    if (SitePreferences.DISPLAY_NOTIFICATION_STEP) {
                        this.showStepNumber(11);
                    } else {
                        this.skipPhoneStep();
                    }
                } else {
                    this.state.time = `${$('.js-fd-timepicker-hours').attr('data-value')}:${$('.js-fd-timepicker-minutes').attr('data-value')}`;
                    this.storeState();
                    this.showStepNumber(8);
                }
            }
        });
    }

    validateDate() {
        return new Promise(resolve => {
            const flyday = new Date(1 * $('.js-fd-button-calendar-date').attr('data-date'));
            const hours = $('.js-fd-timepicker-hours').attr('data-value');
            const minutes = $('.js-fd-timepicker-minutes').attr('data-value');
            let result = false;

            flyday.setHours(parseInt(hours, 10));
            flyday.setMinutes(parseInt(minutes, 10));

            $.ajax({
                url: util.appendParamsToUrl(Urls.flightDetailsTimeValidation, {
                    t: moment(flyday).format('dddd, DD.MM.YYYY hh:mm A'),
                    c: this.state.city
                })
            }).done(data => {
                if (!data || !data.success) {
                    result = this.state.citytype === 'arrival' ? 'js-select-time-error-arriving' : 'js-select-time-error-leaving';
                    return resolve(result);
                }

                this.state.date = $('.js-fd-button-calendar-date').text();
                this.state.time = `${hours}:${minutes}`;
                resolve(result);
            });
        });
    }

    validateMonth () {
        let today = new Date(),
            flyday = new Date(1 * $('.js-fd-button-calendar-date').attr('data-date'));

        today.setMonth(today.getMonth() + TIME.months);
        return flyday.getTime() < today.getTime();
    }
/*
* mode: a flag for processing connection points:
* true  - collect connection points values
* false - render connection poins selectors
*/
    prepareConnections (mode = false) {
        let number = 1 * $('.js-fd-connection-quantity').val(),
            isProcess = true;

        if (mode) {
            this.state.connections = [];
            for (let i = 1; i < 4; i++) {
                if (i <= number) {
                    this.state.connections.push($(`.js-fd-connection-item-value-${i}`).attr('data-label'));
                }
            }
        } else {
            $('.js-fd-connection-label-from').text($('.js-fd-country-connection-from').attr('data-label'));
            $('.js-fd-connection-number-value').text(number);
            $('.js-fd-connection-label-to').text($('.js-fd-country-connection-to').attr('data-label'));

            for (let i = 1; i < 4; i++) {
                if (i <= number) {
                    $(`.js-fd-connection-item-${i}`).removeClass('h-hidden');
                    if (this.state.connections[i - 1]) {
                        $(`.js-fd-connection-item-value-${i} .js-country-selector-value`).text(this.state.connections[i - 1]);
                        $(`.js-fd-connection-item-value-${i}`).attr('data-label', this.state.connections[i - 1]);
                    } else {
                        isProcess = false;
                    }
                } else {
                    $(`.js-fd-connection-item-${i}`).addClass('h-hidden');
                }
            }
            if (isProcess) {
                $('.js-fd-button-connection-proceed').removeClass('disabled');
                $('.js-fd-button-billing-cfd').removeClass('disabled');
            }
        }
    }

    storeState () {
        window.localStorage.setItem('fdState', JSON.stringify(this.state));
    }

    prefillSelectors () {
        let selected = this.sessionCountry;

        if (this.state.citytype === 'departure') {
            this.renderSelector('.js-fd-country-from');
            this.renderSelector('.js-fd-country-connection-from');
            this.renderCountrySelector('.js-fd-country-to', selected);
            this.renderCountrySelector('.js-fd-country-connection-to', selected);
        } else {
            this.renderSelector('.js-fd-country-to');
            this.renderSelector('.js-fd-country-connection-to');
            this.renderCountrySelector('.js-fd-country-from', selected);
            this.renderCountrySelector('.js-fd-country-connection-from', selected);
        }
        if ($('.js-fd-country-from').attr('data-value') !== '' && $('.js-fd-country-to').attr('data-value') !== '') {
            $('.js-fd-button-from-to').removeClass('disabled');
            $('.js-fd-button-billing-fd').removeClass('disabled');
        } else {
            $('.js-fd-button-from-to').addClass('disabled');
            $('.js-fd-button-billing-fd').addClass('disabled');
        }
        if ($('.js-fd-country-connection-from').attr('data-value') !== '' && $('.js-fd-country-connection-to').attr('data-value') !== '') {
            $('.js-fd-button-connection-points').removeClass('disabled');
        } else {
            $('.js-fd-button-connection-points').addClass('disabled');
        }
    }

    renderSelector (el) {
        let $item;

        $item = $(`${el} ul li[data-value="${DEFAULT_COUNTRY}"]`);
        $(el).addClass('disabled')
            .attr('data-value', $item.attr('data-value'))
            .attr('data-label', $item.text());
        if ($item.attr('data-value')) {
            $(`${el} .js-country-selector-value`).text($item.text());
        }
    }

    renderCountrySelector (el, country) {
        let $item;

        $(el).removeClass('disabled');
        if (country) {
            $item = $(`${el} ul li[data-value="${country}"]`);
            $(el).attr('data-value', $item.attr('data-value'))
                .attr('data-label', $item.text());
            if ($item.attr('data-value')) {
                $(`${el} .js-country-selector-value`).text($item.text());
            }
        } else {
            $(`${el} .js-country-selector-value`).text('Select Country');
            $(el).attr('data-value', '').attr('data-label', '');
        }
    }

    /*
    * a helper function for show first parameter and hide other
    * params - an array of string to use it as jQuery selector
    * show/hide makes via adding/removing 'h-hidden' class
    */
   showAndHide (params) {
       $(params[0]).removeClass('h-hidden');
       for (let i = 1; i < params.length; i++) {
            $(params[i]).addClass('h-hidden');
       }
   }

    setWorkTime() {
        return new Promise(resolve => {
            const flyday = new Date(1 * $('.js-fd-button-calendar-date').attr('data-date'));

            $.ajax({
                url: this.workTimeURL,
                data: {
                    city: this.state.city,
                    date: moment(flyday).format('DD.MM.YYYY')
                }
            }).done(data => {
                if (data && data.success) {
                    $('.js-timepicker-component').attr('data-start', data.start_at);
                    $('.js-timepicker-component').attr('data-stop', data.stop_at);
                } else {
                    $('.js-timepicker-component').attr('data-start', '00:00');
                    $('.js-timepicker-component').attr('data-stop', '24:00');
                }
                resolve();
            })
            .fail(() => {
                $('.js-timepicker-component').attr('data-start', '00:00');
                $('.js-timepicker-component').attr('data-stop', '24:00');
                resolve();
            });
        });
   }

   checkFlightNumber () {
    let number = $('.js-fd-input-flight-number').val().trim();

    $('.js-fd-button-flightnumber-next').addClass('disabled');
    if (number === '') {
         $('.js-fd-flightnumber-error').removeClass('h-hidden').text('This field is required');
         return;
    }

    $.ajax({
         url: this.checkURL,
         data: {
             number: number
         }
     }).done(data => {
         if (data.success) {
             $('.js-fd-button-flightnumber-next').removeClass('disabled');
             $('.js-fd-flightnumber-error').addClass('h-hidden');
             this.state.flightNumber = number;
             $('form.b-flightdetails .js-fd-form-flightnumber').val(this.state.flightNumber);
             if (SitePreferences.DISPLAY_NOTIFICATION_STEP) {
                 this.showStepNumber(11);
             } else {
                 this.skipPhoneStep();
             }
         } else {
             $('.js-fd-flightnumber-error').removeClass('h-hidden').text('Please enter a valid flight number');
         }
     })
     .fail(() => {
         $('.js-fd-button-flightnumber-next').removeClass('disabled');
     });
   }

   saveCountry () {
        let country = this.isStaff && !this.state.isFlying ? 'IE' : this.state.country.to.value,
            url = util.appendParamsToUrl(Urls.flightDetailsSaveCountry, {
            country: country,
            format: 'ajax'
        });

        $.ajax({
            url: url
        }).done(() => {
            // do nothing
        });
    }

    changeCityType() {
        // according to requirement below citytype should be always arrival
        if (this.isStaff && !this.state.isFlying && this.state.city === 'Dublin' && (this.state.terminal === 'Terminal 1' || this.state.terminal === 'Terminal 2' || this.state.terminal === 'Clive' || this.state.terminal === 'Grattan')) {
            this.state.citytype = 'arrival';
        } else if (this.isStaff && !this.state.isFlying && this.state.city === 'Cork') {
            this.state.citytype = 'arrival';
        }
    }

    setTimeMargins () {
        try {
            this.margins = JSON.parse(this.$el.attr('data-margins-json'));
        } catch (e) {
            this.margins = null;
        }
        if (this.margins) {
            TIME.Dublin = this.margins.minimalDublin;
            TIME.Cork = this.margins.minimalCork;
            TIME.months = this.margins.monthLimit;
            TIME.strictDublin = this.margins.strictDublin;
        }
    }

    prepareStaffTime () {
        let time = new Date();

        time.setHours(time.getHours() + TIME.strictDublin);
        this.state.date = moment(time).format('dddd, DD MMMM YYYY');
        this.state.time = moment(time).format('HH:mm');
    }

    setTerminalLabels () {
        if (this.margins) {
            $('.js-fd-terminal-config-terminal1').text(this.margins.terminals.dublin.regular.terminal1);
            $('.js-fd-terminal-config-terminal2').text(this.margins.terminals.dublin.regular.terminal2);
            $('.js-fd-terminal-config-terminal1-staff').text(this.margins.terminals.dublin.staff.terminal1);
            $('.js-fd-terminal-config-terminal2-staff').text(this.margins.terminals.dublin.staff.terminal2);
            $('.js-fd-terminal-config-grattan').text(this.margins.terminals.dublin.staff.grattan);
            $('.js-fd-terminal-config-clive').text(this.margins.terminals.dublin.staff.clive);
            $('.js-fd-terminal-config-arrival').text(this.margins.terminals.cork.arrival);
            $('.js-fd-terminal-config-departure').text(this.margins.terminals.cork.departure);
        }
    }

    configTerminals () {
        let config;

        try {
            config = JSON.parse(this.$el.attr('data-terminals-json'));
        } catch (e) {
            config = null;
        }

        if (config) {
            if (config.dublin.staff.terminal1.hidden) {
                $('.js-fd-button-terminal1-staff').remove();
            }
            if (config.dublin.staff.terminal2.hidden) {
                $('.js-fd-button-terminal2-staff').remove();
            }
            if (config.dublin.staff.grattan.hidden) {
                $('.js-fd-button-grattan').remove();
            }
            if (config.dublin.staff.clive.hidden) {
                $('.js-fd-button-clive').remove();
            }
        }
    }

    fillForm () {
        $('form.b-flightdetails .js-fd-form-citytype').val(this.state.citytype);
        $('form.b-flightdetails .js-fd-form-city').val(this.state.city);
        if (this.state.citytype === 'departure') {
            $('form.b-flightdetails .js-fd-form-country').val(this.state.isFlying ? this.state.country.to.value : '');
        } else {
            $('form.b-flightdetails .js-fd-form-country').val(this.state.isFlying ? this.state.country.from.value : '');
        }
        $('form.b-flightdetails .js-fd-form-isflying').val(this.state.isFlying ? 'yes' : 'no');
        $('form.b-flightdetails .js-fd-form-date').val(this.state.date + ' ' + this.state.time);
        $('form.b-flightdetails .js-fd-form-terminal').val(this.state.terminal);
        $('form.b-flightdetails .js-fd-form-istransfer').val(this.state.isTransfer && this.state.isFlying ? 'yes' : 'no');
        $('form.b-flightdetails .js-fd-form-transfercountry').val(this.getTransferCountries());
        $('form.b-flightdetails .js-fd-form-phone').val(this.state.phone ? `${$('.js-fd-phonenumber-wrapper select').val()} ${this.state.phone}` : '');
        $('form.b-flightdetails .js-fd-form-flightnumber').val(this.state.flightNumber);
    }

    getTransferCountries () {
        // country list gets from first country selector component
        let countries,
            result = [],
            locale = $('.js-fd-country-from').data('locale');

        if (!this.state.isTransfer || !this.state.isFlying || this.state.connections.length === 0) {
            return '';
        }

        countries = $('.js-fd-country-from').data('json-config')[locale];
        this.state.connections.map(name => {
            for (let item in countries) {
                if (name === countries[item].label) {
                    result.push(countries[item].value);
                }
            }
        });
        // only first country will be processed bu requirement from client
        return result[0];
    }
}

module.exports = FlightDetailsSteps;
